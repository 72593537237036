.hero {
  background-image: url("../../../../../public/images/bg.webp");
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  padding-right: 50px;
  color: #fff;
  /* border: 2px solid red; */
}
.hero #heading h3 {
  color: #fff;
}
.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 768px) {
  .margin {
    height: 40vh;
  }
  .buttons{
    display: flex;
    flex-direction: column;
  }
  .habit {
    display: none;
  }
  .button{
    display: none !important;
  }
  .hi{
    display: none;
  }
  .hero {
    background-position: right;
    padding-top: 50%;
    height: 80vh;
    margin-top: 20px;
  }
  .hero .row {
    width: 100%;
  }
}
/* .button {
  padding: 10px 20px;
  background-color: #1eb2a6;
  color: #fff;
  font-weight: 600;
  border: none;
  margin: 30px 10px 0 0;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 24px 36px -11px rgb(0 0 0 / 9%);
  transition: 0.5s;
}

.button:hover {
  background-color: #1eb2a6;
  color: #fff;
} */
