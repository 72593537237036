.testimonal .items {
  padding: 30px;
  transition: 0.5s;
}

.testimonal .img {
  position: relative;
}
.testimonal img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.testimonal i {
  position: absolute;
  bottom: 0;
  left: 60px;
  background-color: #1eb2a6;
  color: #fff;
}
.testimonal .name {
  margin: 25px;
}
.testimonal .name h2 {
  font-size: 20px;
  margin-bottom: 5px;
}
.testimonal .name span {
  color: #1eb2a6;
}
.testimonal p {
  margin-top: 20px;
  color: grey;
  transition: 0.5s;
}
.testimonal .items:hover {
  background-color: #1eb2a6;
  color: #fff;
  cursor: pointer;
}
.testimonal .items:hover span,
.testimonal .items:hover p {
  color: #fff;
}
.testimonal .items:hover i {
  background-color: #fff;
  color: #1eb2a6;
}
@media screen and (max-width: 768px) {
  .customgrid{
    /* border: 2px solid red; */
    display: grid;
    grid-template-columns: 80%;
    justify-content: center; /* Centers items horizontally */
  align-items: center; /* Centers items vertically */
  }
}
